import axios from "@/axios.js";

export default {
  /**
   * Retrieve updated userInfo
   * @param {*} me Vue's `this` instance
   */
  GetMe() {
    return new Promise((resolve, reject) => {
      axios
        .get("me")
        .then((response) => {
          if (!response) return;

          const UI = response.data.data;
          if (![undefined, "undefined", null, "null"].includes(UI.nexlaw_credits)) localStorage.setItem("userInfo", JSON.stringify(UI));

          resolve(response);
        })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Retrieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetCampaigns(args = null) {
    var API = `nexlaw`;
    if (args) {
      API += `?` + Object
        .entries(args)
        .map(([k, v]) => `${k}=${v}`)
        .join(`&`);
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Permanently delete a campaign
   * @param {*} id Campaign ID
   */
  DeleteCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`nexlaw/${id}/delete`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Bookmark campaign
   * @param {*} id Campaign ID
   */
  BookmarkCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/bookmark`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Move campaign into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/archive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Unarchive campaign
   * @param {*} id Campaign ID
   */
  UnarchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/unarchive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },
   legislationSearch(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/legislation-search`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLegislationSearch(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=legislation` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLegislation(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeLegis(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/legislation-summary/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/legislation/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
}